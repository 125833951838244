@content1: content1;
.@{content1}-wrapper {
  //height: 360px;
  padding: 10px 10px 60px 10px;

  background-color:  rgba(250,250,250,1);
  .@{content1} {
    height: 100%;
    padding: 0 24px;
    &-img {
      height: 100%;
      transform-origin: top;
      padding: 0 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        display: block;
        //width: 350px;
        img {
          display: block;
        }
      }
    }
    &-text {
      padding: 0 32px;
      height: 100%;
      .@{content1}-content,
      .@{content1}-title {
        position: relative !important;
      }
      .@{content1}-title {
        font-size: 32px;
        font-weight: normal;
        color: #404040;
        margin-top: 100px;
      }
      .content {
        //margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{content1}-wrapper {

    .@{content1} {
      &-img {
        height: 100%;
        padding: 0;
        text-align: center;
        span {
          display: inline-block;
          width: 300px;
          height: 100%;
          //line-height: 200px;
          margin: auto;
        }
      }
      &-text {
        height: auto;
        margin-bottom: 20px;
        text-align: center;
        padding: 0;
        .@{content1}-content,
        .@{content1}-title {
          width: 100%;
          top: auto;
        }
        .@{content1}-title {
          margin: 32px auto 0 auto;
          font-size: 24px;
        }
      }
    }
  }
}
