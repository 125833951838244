
@banner2: banner2;
.@{banner2} {
  width: 100%;
  height: ~"calc(100vh - 100px)";
  position: relative;
  border-color: #666;
  background: #fff;
  .banner-anim {
    height: 100%;
  }
  & .queue-anim-leaving {
    position: relative !important;
    width: auto;
  }
  .banner-user-elem {
    height: 100%;
    color: #fff;
    position: relative;
    overflow: hidden;
  }

  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    background-size: cover;
  }
  .bg0 {
    background: url("https://images.unsplash.com/photo-1545569310-f6d4b4d7ede9?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3300&q=80") center;
    background-size: cover;
    height: 100%;
    width: ~"calc(100% + 400px)";
    left: -50px;
    &:before {
      width: 100%;
      height: 100%;
      content: '';
      background: fade(#000, 35);
      display: block;
    }
  }
  .banner-user-elem .banner-user-title {
    font-size: 22px;
    top: 40%;
  }
  .banner-user-elem .banner-user-text {
    top: 40%;
  }
  &-page {
    position: relative;
    max-width: 1200px;
    margin: 5% auto auto auto;
  }
  &-text-wrapper {
    display: inline-block;
    font-size: 28px;
    color: @template-text-color-light;
    width: 600px;
    text-align: left;
    line-height: 1.2;
    .@{banner2}-title {
      left: 0;
      margin: auto;
      position: relative;
      font-size: 56px;
      font-weight: bold;
    }
    & .@{banner2}-subtitle {
      word-wrap: break-word;
      margin: 15px auto;
      color: @primary-color;
      font-weight: bold;
    }
    & .@{banner2}-content {
      word-wrap: break-word;
      margin: 15px auto;
    }
    & .@{banner2}-button {
      //border: 1px solid @primary-color;
      //background: @primary-color;
      //transition: background .45s @ease-in, border .45s @ease-in;
      //& span {
      //  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
      //  transition: text-shadow .45s @ease-out;
      //}
      //&:hover {
      //  border-color: @primary-color;
      //  background: @primary-color;
      //  transition: background .45s @ease-out, border .45s @ease-out;
      //  cursor: pointer;
      //  & span {
      //    text-shadow: 1px 1px 3px rgba(0, 0, 0, .35);
      //  }
      //}
      font-weight: bold;
      margin: 5px;
      border: 0;
      color: #fff;
      background: linear-gradient(to right, #14DCAF, #05cbff, #1e5aff) !important;
      height: 42px;
      line-height: 42px;
      font-size: 18px;
      border-radius: 30px;
      padding: 0 15px;
      display: inline-block;
      transition: transform .3s, box-shadow .3s;
      width: 250px;
    }
    & .@{banner2}-button1 {
      //border: 1px solid @primary-color;
      //background: @primary-color;
      //transition: background .45s @ease-in, border .45s @ease-in;
      //& span {
      //  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
      //  transition: text-shadow .45s @ease-out;
      //}
      //&:hover {
      //  border-color: @primary-color;
      //  background: @primary-color;
      //  transition: background .45s @ease-out, border .45s @ease-out;
      //  cursor: pointer;
      //  & span {
      //    text-shadow: 1px 1px 3px rgba(0, 0, 0, .35);
      //  }
      //}
      font-weight: bold;
      margin: 5px;
      border: 0;
      color: #fff;
      background: linear-gradient(to right, #1e5aff, #2F73EB, #de0a1c) !important;
      height: 42px;
      line-height: 42px;
      font-size: 18px;
      border-radius: 30px;
      padding: 0 15px;
      display: inline-block;
      transition: transform .3s, box-shadow .3s;
      width: 250px;
    }
  }
  &-icon {
    bottom: 20px;
    font-size: 24px;
    position: absolute;
    z-index: 10;
    left: 50%;
    margin-left: -12px;
    color: @template-text-color-light;
  }
}

.banner-anim-thumb-default span {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, .5);
}

@media screen and (max-width: 414px) {
  .@{banner2} {
    &-page {
      margin: -50px auto auto auto;
    }
    &-text-wrapper {
      width: 100%;
      left: 0;
      margin: auto;
      padding: 0;
      text-align: center;
      display: block;
      font-size: 21px;
      .@{banner2}-title {
        font-size: 32px;
      }
    }
  }
}
