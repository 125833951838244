@content13: content13;
.@{content13}-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  &.home-page-wrapper {
    .title-wrapper {
      padding: 20px;
    }
  }
  .title-content {

  }
}

@media screen and (max-width: 767px) {

}
